import { cloneDeep } from "lodash"
import { Fragment, useState, useEffect, useMemo, useCallback, useRef } from "react"
import styled from "styled-components"
import { Colors } from "Theme"
import { getPieceCoverage } from "Utils/chess"
import ChessPiece from "./ChessPiece"


const StBoard = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". . . . . . . ."
    ". . . . . . . ."
    ". . . . . . . ."
    ". . . . . . . ."
    ". . . . . . . ."
    ". . . . . . . ."
    ". . . . . . . ."
    ". . . . . . . .";
  font-size: 40px;
  font-weight: bold;
  border-radius: 4px;
  overflow: hidden;
`

const StSquare = styled.div`
  background: ${props => props.color === 'black' ? Colors.checkboardBlack : Colors.checkboardWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  pointer-events: none;

  &::before, &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all .3s ease-out;
  }

  &::before {
    z-index: 20;
    background: #ff0000;
    opacity: ${props => props.settings.blackCoverage ? props.blackOccupancy : 0};
  }
  
  &::after {
    z-index: 50;
    background: #00ff00;
    opacity: ${props => props.settings.whiteCoverage ? props.whiteOccupancy : 0};
    //mix-blend-mode: screen;
  }
`

const StPieceContainer = styled.div`
  width: 12.5%;
  height: 12.5%;
  position: absolute;
  left: ${props => props.coords.x * 12.5}%;
  top: ${props => props.coords.y * 12.5}%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity .3s ease-out;

  & > img {
    width: 80%;
    height: 80%;
  }
`

/**
 * Given a chess position, first computes each square covered by each piece, thus creating a "coverage" value for each square
 * for each player. The coverage is then rendered. 
 */
function ChessPosition ({ position, onUpdatePosition, settings }) {
  const [whiteCoverage, setWhiteCoverage] = useState(new Array(8).fill(new Array(8).fill(0)))
  const [blackCoverage, setBlackCoverage] = useState(new Array(8).fill(new Array(8).fill(0)))

  const boardElem = useRef()
  const pieceMoving = useRef(null)
  const movingCoords = useRef()
  const squareHovered = useRef()
  const lastTouchPos = useRef()

  useEffect(() => {
    // everytime the position changes, computes the coverage board
    const wCoverage = new Array(8)
    const bCoverage = new Array(8)

    for (let i = 0; i < 8; i++) {
      wCoverage[i] = new Array(8).fill(0)
      bCoverage[i] = new Array(8).fill(0)
    }

    // get each piece coverage and adds its to the black or white coverage
    for (let y = 0; y < 8; y++) {
      for (let x = 0; x < 8; x++) {
        const square = position[y][x]

        if (square) {
          const pieceCoverage = getPieceCoverage([x, y], position)

          for (const sq of pieceCoverage) {
            const [sqX, sqY] = sq
            if (square.color === 'b') {
              bCoverage[sqY][sqX]+= 0.15
            }
            else {
              wCoverage[sqY][sqX]+= 0.15
            }
          }
        }
      }
    }

    setWhiteCoverage(wCoverage)
    setBlackCoverage(bCoverage)
  }, [position])

  const pieces = useMemo(() => {
    const pcs = []
    for (let y = 0; y < 8; y++) {
      for (let x = 0; x < 8; x++) {
        if (position[y][x]) {
          pcs.push({
            id: position[y][x].id,
            coords: { x, y },
            type: position[y][x].type,
            color: position[y][x].color,
          })
        }
      }
    }
    return pcs
  }, [position])

  const onMouseDown = (event, id) => {
    event.nativeEvent.preventDefault()
    event.nativeEvent.stopPropagation()
    pieceMoving.current = boardElem.current.querySelector(`#piece-${id}`)
    movingCoords.current = { x: 0, y: 0 }
  }

  const onTouchStart = (event, id) => {
    // event.nativeEvent.preventDefault()
    // event.nativeEvent.stopPropagation()
    pieceMoving.current = boardElem.current.querySelector(`#piece-${id}`)
    movingCoords.current = { x: 0, y: 0 }
    lastTouchPos.current = { x : event.touches[0].clientX, y: event.touches[0].clientY }
  }

  const onMouseMove = useCallback(event => {
    const piece = pieceMoving.current

    if (piece) {
      movingCoords.current.x+= event.movementX
      movingCoords.current.y+= event.movementY
      piece.style.transform = `translate(${movingCoords.current.x}px, ${movingCoords.current.y}px)`
    }
  })

  const onTouchMove = useCallback(event => {
    const piece = pieceMoving.current

    if (piece) {
      event.preventDefault()
      const move = {
        x: event.touches[0].clientX - lastTouchPos.current.x, 
        y: event.touches[0].clientY - lastTouchPos.current.y 
      }
      movingCoords.current.x+= move.x
      movingCoords.current.y+= move.y
      piece.style.transform = `translate(${movingCoords.current.x}px, ${movingCoords.current.y}px)`
      lastTouchPos.current = { x : event.touches[0].clientX, y: event.touches[0].clientY }
    }
  })

  const onMouseUp = event => {
    if (pieceMoving.current) {      
      // find the square in which the piece is dropped
      const bounds = boardElem.current.getBoundingClientRect()
      const X = (((event.clientX - bounds.x) / bounds.width) * 8) | 0
      const Y = (((event.clientY - bounds.y) / bounds.height) * 8) | 0
      
      // given the landing coordinates, updates the position of the board
      let origin = pieceMoving.current.id.split('-')
      origin = `${parseInt(origin[1])}-${parseInt(origin[2])}`
      let originPos = null
      for (let y = 0; y < 8; y++) {
        for (let x = 0; x < 8; x++) {
          if (position[y][x]?.id === origin) {
            originPos = { col: x, row: y }
            break
          }
        }
        if (originPos) break
      }
      const originSquare = position[originPos.row][originPos.col]
      const targetSquare = position[Y][X]

      console.log({ origin, originSquare, targetSquare })

      if (!targetSquare || targetSquare.color !== originSquare.color) {
        const clonedPosition = cloneDeep(position)
        clonedPosition[Y][X] = clonedPosition[originPos.row][originPos.col]
        clonedPosition[originPos.row][originPos.col] = null
        onUpdatePosition(clonedPosition)
      }

      // reset the moving piece
      pieceMoving.current.style.transform = 'none'
      pieceMoving.current = null
    }
  }

  const onTouchEnd = event => {
    if (pieceMoving.current) {      
      // find the square in which the piece is dropped
      const bounds = boardElem.current.getBoundingClientRect()
      const X = (((lastTouchPos.current.x - bounds.x) / bounds.width) * 8) | 0
      const Y = (((lastTouchPos.current.y - bounds.y) / bounds.height) * 8) | 0
      
      // given the landing coordinates, updates the position of the board
      let origin = pieceMoving.current.id.split('-')
      origin = `${parseInt(origin[1])}-${parseInt(origin[2])}`
      let originPos = null
      for (let y = 0; y < 8; y++) {
        for (let x = 0; x < 8; x++) {
          if (position[y][x]?.id === origin) {
            originPos = { col: x, row: y }
            break
          }
        }
        if (originPos) break
      }
      const originSquare = position[originPos.row][originPos.col]
      const targetSquare = position[Y][X]

      console.log({ origin, originSquare, targetSquare })

      if (!targetSquare || targetSquare.color !== originSquare.color) {
        const clonedPosition = cloneDeep(position)
        clonedPosition[Y][X] = clonedPosition[originPos.row][originPos.col]
        clonedPosition[originPos.row][originPos.col] = null
        onUpdatePosition(clonedPosition)
      }

      // reset the moving piece
      pieceMoving.current.style.transform = 'none'
      pieceMoving.current = null
    }
  }

  useEffect(() => {
    boardElem.current.addEventListener('touchmove', onTouchMove)
    return () => {
      boardElem.current.removeEventListener('touchmove', onTouchMove)
    }
  }, [])

  return (
    <StBoard 
      ref={boardElem} 
      onMouseMove={onMouseMove} 
      onMouseUp={onMouseUp}
      onTouchEnd={onTouchEnd}
    >
      {position.map((row, rowIdx) => (
        <Fragment key={rowIdx}>
          {row.map((col, colIdx) => (
            <StSquare 
              key={colIdx}
              color={(rowIdx+colIdx)%2 === 0 ? 'white' : 'black'}
              blackOccupancy={blackCoverage[rowIdx][colIdx]}
              whiteOccupancy={whiteCoverage[rowIdx][colIdx]}
              settings={settings}
            />
          ))}
        </Fragment>
      ))}

      {pieces.map((piece, idx) => (
        <StPieceContainer 
          key={piece.id} 
          coords={piece.coords}
          onMouseDown={event => onMouseDown(event, piece.id)}
          onTouchStart={event => onTouchStart(event, piece.id)}
          id={`piece-${piece.id}`}
          show={settings.showPieces}
        >
          <ChessPiece color={piece.color} type={piece.type} />
        </StPieceContainer>
      ))}
    </StBoard>
  )
}

export default ChessPosition