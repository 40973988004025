import styled from "styled-components"

const StButton = styled.button`
  border: none;
  background: ${props => props.color};
  width: 200px;
  height: 45px;
  font-family: inherit;
  color: white;
  cursor: pointer;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
  font-weight: bold;

  &::before {
    content: '';
    display: block;
    opacity: 0;
    background: blue;
    transition: all .3s ease-out;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0; right: 0;
    mix-blend-mode: screen;
  }

  &:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.4);

    &::before {
      opacity: 0.4;
    }
  }
`

function LoaderButton (props) {
  return <StButton {...props}>{ props.children }</StButton>
}

export default LoaderButton