import { useEffect, useMemo, useState } from 'react'
import Dropzone from 'react-dropzone'
import styled, { css } from 'styled-components'
import { Colors } from 'Theme'
import Chess from 'chess.js'
import LoaderButton from 'Components/Buttons/LoaderButton'


const StContainer = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: all 0.3s ease-out;
  background: rgba(0, 0, 0, 0.85);
  pointer-events: ${props => props.show ? 'all' : 'none'};
  opacity: ${props => props.show ? 1 : 0};
  transform: scale(${props => props.show ? 1 : 1.1});

  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 40px;
    font-size: 32px;
    cursor: pointer;
  }
`

const StDropContainer = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  
  p {
    width: 80%;
    padding: 150px 200px;
    border: 3px dotted white;
    font-size: 2rem;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(.64,.37,.79,1.9);
    background: rgba(255, 255, 255, 0.05);
    box-sizing: border-box;
    border-radius: 10px;
    color: white;
    text-align: center;

    @media (max-width: 1024px) {
      padding: 30px;
    }
    
    &:hover {
      background: rgba(255, 255, 255, 0.12);
    }

    ${props => props.dragging && css`
      transform: scale(1.1);
      border: 4px solid ${Colors.valid};
      color: white;
      background: ${Colors.valid};
    `}
  }
`

const StDroppedInfos = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;

  h2 {
    font-size: 1.3em;
    color: ${Colors.valid};
  }

  h2.error {
    color: ${Colors.error};
  }
`

function FileLoader ({ show, onLoadGame, onHide }) {
  const [draggedFile, setDraggedFile] = useState(null)
  const [droppedFile, setDroppedFile] = useState(null)
  const [gamePGN, setGamePGN] = useState(null)
  const [gameHistory, setGameHistory] = useState(null)

  const reset = () => {
    setDraggedFile(null)
    setGamePGN(null)
    setGameHistory(null)
    setDroppedFile(null)
  }

  useEffect(() => {
    if (show) reset()
  }, [show])

  const onDragEnter = file => {
    setDraggedFile(true)
  }

  const onDrop = files => {
    const file = files[0]

    // reads the file and tries to load it as a PGN string
    const reader = new FileReader()
    reader.addEventListener('load', (event) => {
      const chess = new Chess()
      try {
        chess.load_pgn(event.target.result)
        const history = chess.history()
        setGameHistory(history)
        setGamePGN(chess)
      }
      catch (exp) {}
      setDroppedFile(file)
    })
    reader.readAsText(file)

    setDraggedFile(null)
    setGamePGN(null)
    setGameHistory(null)
  }

  const gameHeader = useMemo(() => {
    if (!gamePGN) return null
    return gamePGN.header()
  }, [gamePGN])

  const loadGame = () => {
    onHide()
    onLoadGame(gamePGN)
  }

  return (
    <StContainer show={show}>
      {droppedFile ? (
        <StDroppedInfos>
          {gameHistory?.length > 0 ? (
            <>
              <h2>Game found</h2>
              {gameHeader.Event && <p>{gameHeader.Event}</p>}
              {gameHeader.White && gameHeader.Black && <p>{gameHeader.White} - {gameHeader.Black}</p>}
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 15,
                gap: 15,
              }}>
                <LoaderButton onClick={reset} color={Colors.error}>Try another file</LoaderButton>
                <LoaderButton onClick={loadGame} color={Colors.valid}>Load game</LoaderButton>
              </div>
            </>
          ):(
            <>
              <h2 className="error">Error !</h2>
              <p>No game was found in the provided file</p>
              <LoaderButton onClick={reset} color={Colors.valid}>Try another file</LoaderButton>
            </>
          )}
        </StDroppedInfos>
      ):(
        <Dropzone 
          onDragEnter={onDragEnter}
          onDragLeave={() => setDraggedFile(null)}
          onDrop={onDrop}
        >
          {({getRootProps, getInputProps}) => (
            <StDropContainer dragging={draggedFile !== null} {...getRootProps()}>
              <input {...getInputProps()} />
              <p>
                {draggedFile
                  ? 'Drop to load the file'
                  : 'Load a .pgn file by dropping it in the window or by clicking in this area'
                }
              </p>
            </StDropContainer>
          )}
        </Dropzone>
      )}

      <i className="fas fa-times close" onClick={onHide} />
    </StContainer>
  )
}

export default FileLoader