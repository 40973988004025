import { forwardRef } from 'react'
import styled from 'styled-components'

const StButton = styled.button`
  border: none;
  background: none;
  color: white;
  font-size: 32px;
`

const ButtonIcon = forwardRef((props, ref) => {
  return (
    <StButton {...props} ref={ref}>
      <i className={`fas fa-${props.icon}`}/>
    </StButton>
  )
})

export default ButtonIcon