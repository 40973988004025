import { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import ButtonIcon from './ButtonIcon'
import ChessPosition from './ChessPosition'


const StChessWrapper = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
`

const StControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: 20px;

  ${props => props.disabled && css`
    opacity: 0.2;
    pointer-events: none;
  `}
`

const StSliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 2;
  gap: 10px;

  input[type="range"] {
    width: 100%;
  }

  span {
    font-size: 18px;
  }
`

function ChessViewer ({ positions, settings }) {
  const [index, setIndex] = useState(0)
  const [manualPosition, setManualPosition] = useState(null)

  const prev = () => {
    if (index > 0) {
      setManualPosition(null)
      setIndex(index - 1)
    }
  }
  
  const next = () => {
    if (index < positions.length - 1) {
      setManualPosition(null)
      setIndex(index + 1)
    }
  }

  useEffect(() => {
    const onKeyDown = event => {
      const key = event.key
      if (key === 'ArrowRight') {
        next()
      }
      if (key === 'ArrowLeft') {
        prev()
      }
    }

    
    window.addEventListener('keydown', onKeyDown)
    
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [index])

  useEffect(() => {
    setManualPosition(null)
    setIndex(0)
  }, [positions])

  return (
    <>
      <StChessWrapper>
        <ChessPosition
          position={manualPosition || (positions && positions[index])}
          onUpdatePosition={setManualPosition}
          settings={settings}
        />
      </StChessWrapper>

      <StControlsWrapper disabled={!positions}>
        <ButtonIcon 
          onClick={() => prev()}
          icon="angle-left"
          />
        <StSliderWrapper>
          <span>{index+1} / {positions.length}</span>
          <input 
            type="range"
            value={index}
            min="0"
            max={positions.length-1}
            step="1"
            onChange={evt => { setManualPosition(null); setIndex(parseInt(evt.target.value))}}
          />
        </StSliderWrapper>
        <ButtonIcon 
          onClick={() => next()}
          icon="angle-right"
        />
      </StControlsWrapper>
    </>
  )
}

export default ChessViewer