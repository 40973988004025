import styled from 'styled-components'


function ChessPiece ({ type, color }) {
  return (
    <img
      src={`./assets/pieces/alpha/${color}${type}.svg`}
      alt={(color + type).toUpperCase()}
    />
  )
}

export default ChessPiece