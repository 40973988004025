import Chess from "chess.js"


function isSquareOnBoard (x, y) {
  return !(x < 0 || x > 7 || y < 0 || y > 7)
}

/**
 * Returns a list of squares covered by a rook
 */
function getRookCoverage (x, y, board) {
  const squares = []
  // left
  for (let dx = x-1; dx >= 0; dx--) {
    squares.push([ dx, y ])
    if (board[y][dx] !== null) break
  }
  // right
  for (let dx = x+1; dx <= 7; dx++) {
    squares.push([ dx, y ])
    if (board[y][dx] !== null) break
  }
  // bottom
  for (let dy = y+1; dy <= 7; dy++) {
    squares.push([ x, dy ])
    if (board[dy][x] !== null) break
  }
  // top
  for (let dy = y-1; dy >= 0; dy--) {
    squares.push([ x, dy ])
    if (board[dy][x] !== null) break
  }
  return squares
}

/**
 * Returns a list of sqaures covered by a knight
 */
function getKnightCoverage (x, y, board) {
  const squares = []
  const knightSquares = [
    [ 1, 2 ], [ 2, 1 ],
    [ 2, -1 ], [ 1, -2 ],
    [ -1, -2 ], [ -2, -1 ],
    [ -2, 1 ], [ -1, 2 ]
  ]
  for (const sq of knightSquares) {
    if (isSquareOnBoard(x + sq[0], y + sq[1])) {
      squares.push([ x + sq[0], y + sq[1] ])
    }
  }
  return squares
}

function getBishopCoverage (x, y, board) {
  const squares = []

  // top right
  for (let i = 1; i < 8; i++) {
    const dx = x + i
    const dy = y - i
    if (!isSquareOnBoard(dx, dy)) break
    squares.push([ dx, dy ])
    if (board[dy][dx] !== null) break
  }

  // bottom right
  for (let i = 1; i < 8; i++) {
    const dx = x + i
    const dy = y + i
    if (!isSquareOnBoard(dx, dy)) break
    squares.push([ dx, dy ])
    if (board[dy][dx] !== null) break
  }

  // bottom left
  for (let i = 1; i < 8; i++) {
    const dx = x - i
    const dy = y + i
    if (!isSquareOnBoard(dx, dy)) break
    squares.push([ dx, dy ])
    if (board[dy][dx] !== null) break
  }

  // top left
  for (let i = 1; i < 8; i++) {
    const dx = x - i
    const dy = y - i
    if (!isSquareOnBoard(dx, dy)) break
    squares.push([ dx, dy ])
    if (board[dy][dx] !== null) break
  }

  return squares
}

function getKingCoverage (x, y, board) {
  const squares = []
  for (let i = -1; i <= 1; i++) {
    for (let j = -1; j <= 1; j++) {
      if (i === 0 && j === 0) continue
      const dx = x + i
      const dy = y + j
      if (!isSquareOnBoard(x+i, y+j)) continue
      squares.push([ dx, dy ])
    } 
  }
  return squares
}

function getPonCoverage (x, y, side, board) {
  const squares = []
  const dy = y + side
  let dx = x - 1
  if (isSquareOnBoard(dx, dy)) {
    squares.push([ dx, dy ])
  }
  dx = x + 1
  if (isSquareOnBoard(dx, dy)) {
    squares.push([ dx, dy ])
  }
  return squares
}

/**
 * Given 2D board coordinates and a board, returns a list of 2D coordinates covered by the piece
 * @param {[number, number]} pieceCoordinates [x; y] 
 * @param {*} board 
 */
export function getPieceCoverage (pieceCoordinates, board) {
  const [x, y] = pieceCoordinates
  const piece = board[y][x]
  let coverage = []
  
  if (piece.type === 'r') {
    coverage = getRookCoverage(x, y, board)
  }
  else if (piece.type === 'n') {
    coverage = getKnightCoverage(x, y, board)
  }
  else if (piece.type === 'b') {
    coverage = getBishopCoverage(x, y, board)
  }
  else if (piece.type === 'k') {
    coverage = getKingCoverage(x, y, board)
  }
  else if (piece.type === 'q') {
    let cvg1 = getRookCoverage(x, y, board)
    let cvg2 = getBishopCoverage(x, y, board)
    coverage = cvg1.concat(cvg2)
  }
  else if (piece.type === 'p') {
    coverage = getPonCoverage(x, y, piece.color === 'b' ? 1 : -1, board)
  }

  return coverage
}

/**
 * 
 */
function isSquareEqual (a, b) {
  if (!a && b || a && !b) return false
  if (!a && !b) return true
  if (a.type !== b.type || a.color !== b.color) return false
  return true
}

/**
 * Given a PGN string outputs an array of positions
 * @param {string} pgnString the PGN of the file from which to extract the positions
 */
export function getPositionsFromPGN (chess) {
  const moves = chess.history({ verbose: true })
  
  const positions = []
  const chess2 = new Chess()
  let lastBoard = chess2.board()
  
  // associate to each piece a unique ID
  for (let y = 0; y < 8; y++) {
    for (let x = 0; x < 8; x++) {
      if (lastBoard[y][x]) {
        lastBoard[y][x].id = `${x}-${y}`
      }
    }
  }
  positions.push(lastBoard)
  
  for (const move of moves) {
    chess2.move(move)
    let board = chess2.board()
  
    // where the piece comes from we can get its ID
    const from = move.from
    const fromX = from[0].charCodeAt() - 'a'.charCodeAt()
    const fromY = 7 - (parseInt(from[1]) - 1)
    const to = move.to
    const toX = to[0].charCodeAt() - 'a'.charCodeAt()
    const toY = 7 - (parseInt(to[1]) - 1)
    board[toY][toX].id = lastBoard[fromY][fromX].id
  
    // a list of squares to avoid
    const avoidSquares = []
    avoidSquares.push({ x: toX, y: toY })
  
    // special case for castle
    if (move.san === 'O-O' || move.san === 'O-O-O') {
      // do the same for the rook location
      let sq1X = move.san === 'O-O' ? 7 : 0
      let sq1Y = 0
      let sq2X = move.san === 'O-O' ? 5 : 3
      let sq2Y = 0
      if (move.color === 'w') {
        sq1Y = 7
        sq2Y = 7
      }
  
      // swap the IDS and then add to the list of squares to avoid
      board[sq2Y][sq2X].id = lastBoard[sq1Y][sq1X].id
      avoidSquares.push({ x: sq2X, y: sq2Y })
    }
  
    // we exclude where the piece went to for the ID reconstruction (since it was already done)
    for (let y = 0; y < 8; y++) {
      for (let x = 0; x < 8; x++) {
        let toContinue = false
        for (let toAvoid of avoidSquares) {
          if (toAvoid.x === x && toAvoid.y === y) {
            toContinue = true
          }
        }
        if (toContinue) continue
  
        if (board[y][x]) {
          board[y][x].id = lastBoard[y][x].id
        }
      }
    }
  
    positions.push(board)
    lastBoard = board
  }

  return positions
}