import styled from "styled-components"

const StInfos = styled.div`
  width: 300px;
`

function ToolInfos () {
  return (
    <StInfos className="panel">
      <h2>Chess Coverage</h2>
      <p>
        <strong>Chess Coverage</strong> is a tool to explore the squares controlled by both players at a given position. You can move the pieces freely or load a .PGN file to explore a particular game.
      </p>
      <p style={{ marginTop: 30 }}>
        Created by <a href="https://ciphrd.com" target="_blank">ciphrd</a>.<br />
        <a href="https://github.com/bcrespy/chess-coverage" target="_blank">Source code on Github</a>
      </p>
    </StInfos>
  )
}

export default ToolInfos