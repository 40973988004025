import { useMemo } from 'react'
import styled from 'styled-components'
import { Colors } from 'Theme'
import LoaderButton from './Buttons/LoaderButton'
import Checkbox from './Checkbox'


const StContainer = styled.div`
  width: 300px;
  height: 100%;
`

const StSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 15px;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  ul {
    padding: 0;

    li {
      list-style: none;
    }
  }
`

function Settings ({ game, settings, onUpdateSetting, onReset }) {
  const gameHeader = useMemo(() => game.header(), [game])

  return (
    <StContainer className="panel">
      <h2>Settings</h2>

      <StSection>
        <h3>PGN file</h3>
        <p>
          Currently loaded: <br/>
          <strong>
            {gameHeader.Event && <>{gameHeader.Event}<br/></>}
          </strong>
        </p>
        {gameHeader.White && gameHeader.Black && <p>(W) {gameHeader.White}<br/>(B) {gameHeader.Black}</p>}
        <LoaderButton 
          color={Colors.valid} 
          onClick={()=> onUpdateSetting('showLoader', true)}
        >
          Load new .PGN file
        </LoaderButton>
      </StSection>

      <StSection>
        <h3>Display settings</h3>
        <ul>
          <li>
            <Checkbox 
              checked={settings.showPieces} 
              onChange={() => onUpdateSetting('showPieces', !settings.showPieces)}
            >
              <span>Show pieces</span>
            </Checkbox>
          </li>
          <li>
            <Checkbox 
              checked={settings.whiteCoverage} 
              onChange={() => onUpdateSetting('whiteCoverage', !settings.whiteCoverage)}
            >
              <span>White coverage</span>
            </Checkbox>
          </li>
          <li>
            <Checkbox 
              checked={settings.blackCoverage} 
              onChange={() => onUpdateSetting('blackCoverage', !settings.blackCoverage)}
            >
              <span>Black coverage</span>
            </Checkbox>
          </li>
        </ul>
      </StSection>

      <StSection>
        <h3>General</h3>
        <LoaderButton 
          color={Colors.error} 
          onClick={()=> onReset()}
        >
          Reset position
        </LoaderButton>
      </StSection>
    </StContainer>
  )
}

export default Settings