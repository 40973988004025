import pgnTest from 'Assets/alexander-steinkuehler-vs-joseph-henry-blackburne_by_Eligora_2017.11.08.pgn'
import Chess from 'chess.js'
import ChessViewer from "Components/ChessViewer"
import FileLoader from 'Components/FileLoader'
import Settings from 'Components/Settings'
import ToolInfos from 'Components/ToolInfos'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Colors } from 'Theme'
import { getPositionsFromPGN } from "Utils/chess"


// default PGN game
const defaultChess = new Chess()
const pgn = defaultChess.load_pgn(pgnTest)
const defaultPositions = getPositionsFromPGN(defaultChess)

const StRoot = styled.div`
  background: ${Colors.background};
  min-height: 100vh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

const StWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;

  @media (max-width: 1500px) {
    width: 100%;
    padding: 15px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    .panel {
      width: 100%;
    }
  }
`

const StChessContainer = styled.div`
  width: 800px;

  @media (max-width: 1500px) {
    width: auto;
    flex-grow: 2;
  }

  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 100px;
  }
`

function App () {
  const [positions, setPositions] = useState(defaultPositions)
  const [showLoader, setShowLoader] = useState(false)
  const [game, setGame] = useState(defaultChess)

  const [settings, setSettings] = useState({
    showLoader: false,
    whiteCoverage: true,
    blackCoverage: true,
    showPieces: true,
  })

  const updateSetting = (key, value) => {
    setSettings({
      ...settings,
      [key]: value,
    })
  }

  const onLoadGame = game => {
    setPositions(getPositionsFromPGN(game))
    setGame(game)
  }

  useEffect(() => {
    onLoadGame(defaultChess)
  }, [])

  return (
    <StRoot>
      <StWrapper>
        <ToolInfos />

        <StChessContainer>
          <ChessViewer positions={positions} settings={settings} />
        </StChessContainer>

        <Settings
          settings={settings}
          onUpdateSetting={updateSetting}
          game={game}
          onReset={() => onLoadGame(defaultChess)}
        />
      </StWrapper>

      <FileLoader 
        show={settings.showLoader} 
        onLoadGame={onLoadGame}
        onHide={() => updateSetting('showLoader', false)}
      />
    </StRoot>
  )
}

export default App;
